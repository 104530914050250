<template>
  <div class="main">
    <div class="main_box">
      <div class="main_title">
        <h2>{{dataObj.title}}</h2>
        <div class="flex_center main_info">
          <div class="flex main_info_l">
            <div class="main_info_l_tag">福建省</div>
            <div class="main_info_l_tag">2023年5月</div>
          </div>
          <p class="main_info_r">【阅读次数：21 】</p>
        </div>
      </div>
      <div class="flex_center main_operation">
        <el-link type="primary" class="main_operation_item">在线查看</el-link>
        <el-link type="primary" class="main_operation_item">下载原文档</el-link>
        <el-button size="mini" icon="el-icon-star-off" class="main_operation_item">收藏</el-button>
      </div>
      <div class="main_content" v-html="dataObj.body">内容</div>
    </div>
  </div>
</template>

<script>
  import { urlCecnNewsDetail } from '@/api/engineering'
  export default {
    name: "quotaDetails",
    data() {
      return {
        dataObj: {},
        treeNodeJson: {}
      }
    },
    mounted() {
      this.getCecnNewsDetail()
    },
    methods: {
      async getCecnNewsDetail() {
        const { detailId } = this.$route.query
        const param = `detailId=${detailId}`
        const res = await urlCecnNewsDetail(param)
        this.treeNodeJson = JSON.parse(res.data.treeNodeJson)
        this.dataObj = res.data
      }
    }
  }
</script>

<style scoped lang="less">
  .main {
    text-align: left;
    .main_box {
      .main_title {
        border-bottom: 1px solid #DCDCDC;
        margin-bottom: 20px;
        > h2 {
          font-size: 20px;
          font-weight: bold;
          color: #212121;
          padding-bottom: 20px;
        }
        .main_info {
          justify-content: space-between;
          padding-bottom: 20px;
          .main_info_l {
            margin: 0 -6px;
            .main_info_l_tag {
              height: 28px;
              padding: 0 6px;
              margin: 0 6px;
              background-color: #F6F7FB;
              border-radius: 4px;
              font-size: 16px;
              color: #4E4E4E;
              line-height: 28px;
            }
          }
          .main_info_r {
            font-size: 14px;
            color: #4E4E4E;
          }
        }
      }
      .main_operation {
        justify-content: flex-end;
        padding-bottom: 20px;
        .main_operation_item {
          margin-left: 20px;
        }
      }
    }
  }
</style>